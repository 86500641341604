import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TranslationsService} from './services/translations.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ghost-handler-website';

  constructor(
    public translate: TranslateService, public translationsService: TranslationsService) { }

  ngOnInit() {
    if (! this.translate.currentLang) {
      const browserLang = this.translate.getBrowserLang();
      if (this.translationsService.getSupportedLanguages().indexOf(browserLang) > -1){
        this.translate.use(browserLang);
      } else {
        this.translate.use('en');
      }
    }
  }
}
